import cn from 'clsx';
import React from 'react';

import styles from './progress-indicator.module.scss';
import { ProgressIndicatorProps } from './progress-indicator.types';
export const ProgressIndicator = ({
  className,
  variant = 'balance',
  fillDirection = 'left',
  delay,
  staticWidth,
  ...props
}: ProgressIndicatorProps) => {
  const progressContainerIndicatorClasses = cn(
    styles['container'],
    styles[`variant--${variant}`],
    styles[`fill--${fillDirection}`],
    className,
  );

  if (staticWidth) {
    return (
      <div
        role={'progressbar'}
        className={progressContainerIndicatorClasses}
        {...props}
      >
        <div
          style={
            { '--width': staticWidth, minHeight: 2 } as React.CSSProperties
          }
          className={styles['container--progressbar-static']}
        ></div>
      </div>
    );
  }

  return (
    <div
      role={'progressbar'}
      className={progressContainerIndicatorClasses}
      {...props}
    >
      <div
        style={{ '--delay': delay } as React.CSSProperties}
        className={styles['container--progressbar']}
      ></div>
    </div>
  );
};
