import cn from 'clsx';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';

import { LogoProps } from '@/shared/ui/logo/logo.types';

import styles from './logo.module.scss';

export const Logo = ({ className }: LogoProps) => {
  const containerClasses = cn(styles.container, className);

  return (
    <div className={styles.wrapper}>
      <Link className={'anchor'} href={'/'} />

      <Image
        loading={'eager'}
        className={containerClasses}
        src={'/assets/logo/logo-with-name.svg'}
        priority={true}
        fill
        fetchPriority={'high'}
        quality={100}
        alt={'blox way logo'}
        style={{
          borderRadius: 'inherit',
          objectFit: 'cover',
        }}
      />
    </div>
  );
};
