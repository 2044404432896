import cn from 'clsx';

import { Illustration } from '@/shared/ui';
import { TagInfoProps } from '@/shared/ui/tag-info/tag-info.types';
import { Typography } from '@/shared/ui/typography';

import styles from './tag-info.module.scss';
export const TagInfo = ({
  className,
  variant,
  children,
  icon = (
    <Illustration
      style={{ color: '#FDFDFD' }}
      spanTagClassName={styles['icon']}
      useTagClassName={styles['icon']}
      spriteName={'icons'}
      name={'tag-rarity'}
    />
  ),
  ...props
}: TagInfoProps) => {
  const tagInfoClasses = cn(
    styles['tag-info'],
    styles[`variant--${variant}`],
    className,
  );

  const hideIcon =
    variant === 'discount' ||
    variant === 'discount-2' ||
    variant === 'error' ||
    variant === 'status' ||
    variant === 'timer' ||
    variant === 'info' ||
    variant === 'limited' ||
    variant === 'gift' ||
    variant === 'available' ||
    variant == 'hot-price';

  return (
    <div id={'tag-info'} className={tagInfoClasses} {...props}>
      {!hideIcon && icon}
      {children && <Typography>{children}</Typography>}
      {variant === 'timer' && (
        <Illustration
          spanTagClassName={styles['icon-clock']}
          name={'clock'}
          spriteName={'icons'}
        />
      )}
      {variant === 'info' && (
        <Illustration
          spanTagClassName={styles['icon-info']}
          name={'timer-cancel'}
          spriteName={'icons'}
        />
      )}

      {(variant === 'limited' || variant === 'gift') && (
        <Illustration
          spanTagClassName={styles['icon-gift']}
          name={'gift'}
          spriteName={'icons'}
        />
      )}

      {variant === 'available' && (
        <Illustration
          spanTagClassName={styles['icon-gift-available']}
          name={'gift'}
          spriteName={'icons'}
        />
      )}
    </div>
  );
};
