'use client';
import { useTimer } from '@hooks/client';
import cn from 'clsx';
import React, { useEffect, useMemo } from 'react';

import { Typography } from '@/shared/ui';
import { TagTimerProps } from '@/shared/ui/tag-timer/types';

import styles from './tag-timer.module.scss';

const min = 60;

export const TagTimer: React.FC<TagTimerProps> = ({
  duration = 3600,
  className,
  onTimesUp,
  enableColorDiffByTime,
}) => {
  const { timer, count } = useTimer({
    duration,
    device: 'desktop',
  });

  const wrapperClasses = cn(
    styles['container'],
    { [styles['variant-danger']]: !enableColorDiffByTime },
    {
      [styles[
        `variant-${count > 259200 ? 'success' : count < 259201 && count > 86400 ? 'warn' : 'danger'}`
      ]]: enableColorDiffByTime,
    },
    className,
  );

  useEffect(() => {
    if (!count) {
      onTimesUp();
    }
  }, [count]);

  const time = timer.startsWith('00')
    ? timer.substring(3)
    : timer.includes('days')
      ? timer
      : timer.substring(0, 5);

  return (
    <div className={wrapperClasses}>
      <Typography className={styles['timer']}>{time}</Typography>
    </div>
  );
};
