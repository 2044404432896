import cn from 'clsx';

import { Illustration } from '@/shared/ui';
import { Typography } from '@/shared/ui/typography';

import styles from './label.module.scss';
import {
  LabelDecorationProps,
  LabelIconProps,
  LabelProps,
} from './label.types';

export const Label = ({
  className,
  children,
  variant = 'primary',
  labelVariant,
  icon,
  ...props
}: LabelProps) => {
  const labelClasses = cn(
    styles.label,
    styles[`variant--${variant}`],
    { [styles['size']]: variant === 'primary' },
    className,
  );

  return (
    <div className={labelClasses} {...props}>
      {variant === 'primary' && icon && <Label.Icon variant={icon} />}
      <div className={styles['label-decoration__container']}>
        {labelVariant && variant === 'primary' && (
          <Label.Decoration
            className={styles['label-decoration']}
            variant={labelVariant}
          />
        )}
        <Typography as={'span'} id={'label-text'} className={styles['text']}>
          {children}
        </Typography>
      </div>
    </div>
  );
};

export const LabelDecorationsColorByVariant = {
  purple: 'var(--color-purple-default)',
  black: 'var(--color-black-default)',
  red: 'var(--color-red-default)',
  yellow: 'var(--color-yellow-default)',
} as const;

// eslint-disable-next-line react/display-name
Label.Decoration = ({ variant, ...props }: LabelDecorationProps) => {
  return (
    <svg
      {...props}
      viewBox="0 0 114 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.25"
        d="M80.0905 1C53.4389 1.32823 -4.76019 5.28167 1.4622 25.4055C7.05435 39.6134 86.7397 36.5612 106.401 25.8702C126.062 15.1791 99.0601 4.05459 61.4234 7.9934"
        stroke={LabelDecorationsColorByVariant[variant]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

// eslint-disable-next-line react/display-name
Label.Icon = ({ className, variant, style }: LabelIconProps) => {
  return (
    <Illustration
      spanTagClassName={styles['label-icon']}
      name={variant}
      spriteName={'icons'}
      id={'label-icon'}
    />
  );
};
